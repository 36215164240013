// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class=\"row\">\n  <div class=\"col-md-8 col-md-offset-2\">\n    <table class=\"table muted\">\n      <tr>\n        <td>\n          <a href=\""
    + alias3((helpers.large_graph || (depth0 && depth0.large_graph) || alias2).call(alias1,"node",(depth0 != null ? depth0.node : depth0),"","","*_bytes",{"name":"large_graph","hash":{},"data":data}))
    + "\"><img class=\"img-polaroid\" width=\"200\" src=\""
    + alias3((helpers.large_graph || (depth0 && depth0.large_graph) || alias2).call(alias1,"node",(depth0 != null ? depth0.node : depth0),"","","*_bytes",{"name":"large_graph","hash":{},"data":data}))
    + "\"/></a>\n          <h5 style=\"text-align: center;\">GC Pressure</h5>\n        </td>\n        <td>\n          <a href=\""
    + alias3((helpers.large_graph || (depth0 && depth0.large_graph) || alias2).call(alias1,"node",(depth0 != null ? depth0.node : depth0),"","","gc_pause_*",{"name":"large_graph","hash":{},"data":data}))
    + "\"><img class=\"img-polaroid\" width=\"200\" src=\""
    + alias3((helpers.large_graph || (depth0 && depth0.large_graph) || alias2).call(alias1,"node",(depth0 != null ? depth0.node : depth0),"","","gc_pause_*",{"name":"large_graph","hash":{},"data":data}))
    + "\"/></a>\n          <h5 style=\"text-align: center;\">GC Pause Percentiles</h5>\n        </td>\n        <td>\n          <a href=\""
    + alias3((helpers.large_graph || (depth0 && depth0.large_graph) || alias2).call(alias1,"node",(depth0 != null ? depth0.node : depth0),"","","gc_runs",{"name":"large_graph","hash":{},"data":data}))
    + "\"><img class=\"img-polaroid\" width=\"200\" src=\""
    + alias3((helpers.large_graph || (depth0 && depth0.large_graph) || alias2).call(alias1,"node",(depth0 != null ? depth0.node : depth0),"","","gc_runs",{"name":"large_graph","hash":{},"data":data}))
    + "\"/></a>\n          <h5 style=\"text-align: center;\">GC Runs</h5>\n        </td>\n        <td>\n          <a href=\""
    + alias3((helpers.large_graph || (depth0 && depth0.large_graph) || alias2).call(alias1,"node",(depth0 != null ? depth0.node : depth0),"","","heap_objects",{"name":"large_graph","hash":{},"data":data}))
    + "\"><img class=\"img-polaroid\" width=\"200\" src=\""
    + alias3((helpers.large_graph || (depth0 && depth0.large_graph) || alias2).call(alias1,"node",(depth0 != null ? depth0.node : depth0),"","","heap_objects",{"name":"large_graph","hash":{},"data":data}))
    + "\"/></a>\n          <h5 style=\"text-align: center;\">Heap Objects In-Use</h5>\n        </td>\n      </tr>\n    </table>\n  </div>\n</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"alert alert-warning\">\n            <h4>Notice</h4> No topics exist on this node.\n        </div>\n";
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=helpers.helperMissing;

  return "    <table class=\"table table-condensed\">\n        <tr>\n            <td colspan=\"2\"><strong>"
    + alias1(container.lambda(((stack1 = (depths[1] != null ? depths[1].topics : depths[1])) != null ? stack1.length : stack1), depth0))
    + "</strong> Topics</td>\n            <td colspan=\"7\"></td>\n            <td><strong>"
    + alias1((helpers.commafy || (depth0 && depth0.commafy) || alias3).call(alias2,(depth0 != null ? depth0.total_messages : depth0),{"name":"commafy","hash":{},"data":data}))
    + "</strong> Messages</td>\n            <td><strong>"
    + alias1((helpers.commafy || (depth0 && depth0.commafy) || alias3).call(alias2,(depth0 != null ? depth0.total_clients : depth0),{"name":"commafy","hash":{},"data":data}))
    + "</strong> Clients</td>\n        </tr>\n"
    + ((stack1 = helpers.each.call(alias2,(depth0 != null ? depth0.topics : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </table>\n";
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=helpers.helperMissing, alias4="function";

  return "        <tr>\n            <th colspan=\"3\">Topic</th>\n            <th>Depth</th>\n            <th>Memory + Disk</th>\n            <th colspan=\"4\"></th>\n            <th>Messages</th>\n            <th>Channels</th>\n        </tr>\n        <tr class=\"info\">\n            <td colspan=\"3\">\n                <button class=\"btn-link red tombstone-link\" data-node=\""
    + alias1(container.lambda((depths[1] != null ? depths[1].name : depths[1]), depth0))
    + "\" data-topic=\""
    + alias1(((helper = (helper = helpers.topic_name || (depth0 != null ? depth0.topic_name : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"topic_name","hash":{},"data":data}) : helper)))
    + "\" style=\"padding: 0 6px; border: 0;\">✘</button> "
    + alias1(((helper = (helper = helpers.topic_name || (depth0 != null ? depth0.topic_name : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"topic_name","hash":{},"data":data}) : helper)))
    + "\n            </td>\n            <td>\n                "
    + ((stack1 = helpers["if"].call(alias2,(depths[1] != null ? depths[1].graph_active : depths[1]),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                "
    + alias1((helpers.commafy || (depth0 && depth0.commafy) || alias3).call(alias2,(depth0 != null ? depth0.depth : depth0),{"name":"commafy","hash":{},"data":data}))
    + "\n            </td>\n            <td>"
    + alias1((helpers.commafy || (depth0 && depth0.commafy) || alias3).call(alias2,(depth0 != null ? depth0.memory_depth : depth0),{"name":"commafy","hash":{},"data":data}))
    + " + "
    + alias1((helpers.commafy || (depth0 && depth0.commafy) || alias3).call(alias2,(depth0 != null ? depth0.backend_depth : depth0),{"name":"commafy","hash":{},"data":data}))
    + "</td>\n            <td colspan=\"4\"></td>\n            <td>\n                "
    + ((stack1 = helpers["if"].call(alias2,(depths[1] != null ? depths[1].graph_active : depths[1]),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                "
    + alias1((helpers.commafy || (depth0 && depth0.commafy) || alias3).call(alias2,(depth0 != null ? depth0.message_count : depth0),{"name":"commafy","hash":{},"data":data}))
    + "\n            </td>\n            <td>"
    + alias1((helpers.commafy || (depth0 && depth0.commafy) || alias3).call(alias2,((stack1 = (depth0 != null ? depth0.channels : depth0)) != null ? stack1.length : stack1),{"name":"commafy","hash":{},"data":data}))
    + "</td>\n        </tr>\n"
    + ((stack1 = helpers.unless.call(alias2,((stack1 = (depth0 != null ? depth0.channels : depth0)) != null ? stack1.length : stack1),{"name":"unless","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.program(13, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<a href=\""
    + alias3((helpers.large_graph || (depth0 && depth0.large_graph) || alias2).call(alias1,"topic",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.name : depth0),"","depth",{"name":"large_graph","hash":{},"data":data}))
    + "\"><img width=\"120\" src=\""
    + alias3((helpers.sparkline || (depth0 && depth0.sparkline) || alias2).call(alias1,"topic",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.topic_name : depth0),"","depth",{"name":"sparkline","hash":{},"data":data}))
    + "\"></a>";
},"9":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<a href=\""
    + alias3((helpers.large_graph || (depth0 && depth0.large_graph) || alias2).call(alias1,"topic",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.topic_name : depth0),"","message_count",{"name":"large_graph","hash":{},"data":data}))
    + "\"><img width=\"120\" src=\""
    + alias3((helpers.sparkline || (depth0 && depth0.sparkline) || alias2).call(alias1,"topic",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.topic_name : depth0),"","message_count",{"name":"sparkline","hash":{},"data":data}))
    + "\"></a>";
},"11":function(container,depth0,helpers,partials,data) {
    return "        <tr>\n            <td colspan=\"11\">\n              <div class=\"alert alert-warning\"><h4>Notice</h4> No channels exist for this topic.</div>\n            </td>\n        </tr>\n";
},"13":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.channels : depth0),{"name":"each","hash":{},"fn":container.program(14, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "        <tr>\n            <th width=\"25\"></th>\n            <th colspan=\"2\">Channel</th>\n            <th>Depth</th>\n            <th>Memory + Disk</th>\n            <th>In-Flight</th>\n            <th>Deferred</th>\n            <th>Requeued</th>\n            <th>Timed Out</th>\n            <th>Messages</th>\n            <th>Connections</th>\n        </tr>\n        <tr class=\"warning\">\n            <td></td>\n            <td colspan=\"2\">\n                "
    + alias3(((helper = (helper = helpers.channel_name || (depth0 != null ? depth0.channel_name : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"channel_name","hash":{},"data":data}) : helper)))
    + "\n                "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.paused : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n            </td>\n            <td>\n                "
    + ((stack1 = helpers["if"].call(alias1,(depths[3] != null ? depths[3].graph_active : depths[3]),{"name":"if","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                "
    + alias3((helpers.commafy || (depth0 && depth0.commafy) || alias2).call(alias1,(depth0 != null ? depth0.depth : depth0),{"name":"commafy","hash":{},"data":data}))
    + "\n            </td>\n            <td>"
    + alias3((helpers.commafy || (depth0 && depth0.commafy) || alias2).call(alias1,(depth0 != null ? depth0.memory_depth : depth0),{"name":"commafy","hash":{},"data":data}))
    + " + "
    + alias3((helpers.commafy || (depth0 && depth0.commafy) || alias2).call(alias1,(depth0 != null ? depth0.backend_depth : depth0),{"name":"commafy","hash":{},"data":data}))
    + "</td>\n            <td>"
    + alias3((helpers.commafy || (depth0 && depth0.commafy) || alias2).call(alias1,(depth0 != null ? depth0.in_flight_count : depth0),{"name":"commafy","hash":{},"data":data}))
    + "</td>\n            <td>"
    + alias3((helpers.commafy || (depth0 && depth0.commafy) || alias2).call(alias1,(depth0 != null ? depth0.deferred_count : depth0),{"name":"commafy","hash":{},"data":data}))
    + "</td>\n            <td>"
    + alias3((helpers.commafy || (depth0 && depth0.commafy) || alias2).call(alias1,(depth0 != null ? depth0.requeue_count : depth0),{"name":"commafy","hash":{},"data":data}))
    + "</td>\n            <td>"
    + alias3((helpers.commafy || (depth0 && depth0.commafy) || alias2).call(alias1,(depth0 != null ? depth0.timeout_count : depth0),{"name":"commafy","hash":{},"data":data}))
    + "</td>\n            <td>"
    + alias3((helpers.commafy || (depth0 && depth0.commafy) || alias2).call(alias1,(depth0 != null ? depth0.message_count : depth0),{"name":"commafy","hash":{},"data":data}))
    + "</td>\n            <td>\n                "
    + ((stack1 = helpers["if"].call(alias1,(depths[3] != null ? depths[3].graph_active : depths[3]),{"name":"if","hash":{},"fn":container.program(19, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                "
    + alias3((helpers.commafy || (depth0 && depth0.commafy) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.clients : depth0)) != null ? stack1.length : stack1),{"name":"commafy","hash":{},"data":data}))
    + "\n            </td>\n        </tr>\n"
    + ((stack1 = helpers.unless.call(alias1,((stack1 = (depth0 != null ? depth0.clients : depth0)) != null ? stack1.length : stack1),{"name":"unless","hash":{},"fn":container.program(21, data, 0, blockParams, depths),"inverse":container.program(23, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    return "<span class=\"label label-primary\">paused</span>";
},"17":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<a href=\""
    + alias3((helpers.large_graph || (depth0 && depth0.large_graph) || alias2).call(alias1,"channel",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.topic_name : depth0),(depth0 != null ? depth0.channel_name : depth0),"depth",{"name":"large_graph","hash":{},"data":data}))
    + "\"><img width=\"120\" height=\"20\" src=\""
    + alias3((helpers.sparkline || (depth0 && depth0.sparkline) || alias2).call(alias1,"channel",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.topic_name : depth0),(depth0 != null ? depth0.channel_name : depth0),"depth",{"name":"sparkline","hash":{},"data":data}))
    + "\"></a>";
},"19":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<a href=\""
    + alias3((helpers.large_graph || (depth0 && depth0.large_graph) || alias2).call(alias1,"channel",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.topic_name : depth0),(depth0 != null ? depth0.channel_name : depth0),"clients",{"name":"large_graph","hash":{},"data":data}))
    + "\"><img width=\"120\" height=\"20\" src=\""
    + alias3((helpers.sparkline || (depth0 && depth0.sparkline) || alias2).call(alias1,"channel",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.topic_name : depth0),(depth0 != null ? depth0.channel_name : depth0),"clients",{"name":"sparkline","hash":{},"data":data}))
    + "\"></a>";
},"21":function(container,depth0,helpers,partials,data) {
    return "        <tr>\n            <td colspan=\"11\">\n                <div class=\"alert alert-warning\"><h4>Notice</h4>No clients connected to this channel.</div>\n            </td>\n        </tr>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <tr>\n            <th></th>\n            <th>Client Host</th>\n            <th>User-Agent</th>\n            <th></th>\n            <th>Attributes</th>\n            <th>In-Flight</th>\n            <th>Ready Count</th>\n            <th>Requeued</th>\n            <th>Finished</th>\n            <th>Messages</th>\n            <th>Connected</th>\n        </tr>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.clients : depth0),{"name":"each","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "        <tr>\n            <td></td>\n            <td title=\""
    + alias4(((helper = (helper = helpers.remote_address || (depth0 != null ? depth0.remote_address : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"remote_address","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.hostname || (depth0 != null ? depth0.hostname : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"hostname","hash":{},"data":data}) : helper)))
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.show_client_id : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</td>\n            <td>"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.user_agent : depth0)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</td>\n            <td></td>\n            <td>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.sample_rate : depth0),{"name":"if","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.tls : depth0),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.deflate : depth0),{"name":"if","hash":{},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.snappy : depth0),{"name":"if","hash":{},"fn":container.program(36, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.authed : depth0),{"name":"if","hash":{},"fn":container.program(38, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </td>\n            <td>"
    + alias4((helpers.commafy || (depth0 && depth0.commafy) || alias2).call(alias1,(depth0 != null ? depth0.in_flight_count : depth0),{"name":"commafy","hash":{},"data":data}))
    + "</td>\n            <td>"
    + alias4((helpers.commafy || (depth0 && depth0.commafy) || alias2).call(alias1,(depth0 != null ? depth0.ready_count : depth0),{"name":"commafy","hash":{},"data":data}))
    + "</td>\n            <td>"
    + alias4((helpers.commafy || (depth0 && depth0.commafy) || alias2).call(alias1,(depth0 != null ? depth0.requeue_count : depth0),{"name":"commafy","hash":{},"data":data}))
    + "</td>\n            <td>"
    + alias4((helpers.commafy || (depth0 && depth0.commafy) || alias2).call(alias1,(depth0 != null ? depth0.finish_count : depth0),{"name":"commafy","hash":{},"data":data}))
    + "</td>\n            <td>"
    + alias4((helpers.commafy || (depth0 && depth0.commafy) || alias2).call(alias1,(depth0 != null ? depth0.message_count : depth0),{"name":"commafy","hash":{},"data":data}))
    + "</td>\n            <td>"
    + alias4((helpers.nanotohuman || (depth0 && depth0.nanotohuman) || alias2).call(alias1,(depth0 != null ? depth0.connected : depth0),{"name":"nanotohuman","hash":{},"data":data}))
    + "</td>\n        </tr>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var helper;

  return " ("
    + container.escapeExpression(((helper = (helper = helpers.client_id || (depth0 != null ? depth0.client_id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"client_id","hash":{},"data":data}) : helper)))
    + ")";
},"27":function(container,depth0,helpers,partials,data) {
    var helper;

  return "<small>"
    + container.escapeExpression(((helper = (helper = helpers.user_agent || (depth0 != null ? depth0.user_agent : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"user_agent","hash":{},"data":data}) : helper)))
    + "</small>";
},"29":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                    <span class=\"label label-info\">Sampled "
    + container.escapeExpression(((helper = (helper = helpers.sample_rate || (depth0 != null ? depth0.sample_rate : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"sample_rate","hash":{},"data":data}) : helper)))
    + "%</span>\n";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    <span class=\"label label-warning\" "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.tls_version : depth0),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">TLS</span>\n";
},"32":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "title=\""
    + alias4(((helper = (helper = helpers.tls_version || (depth0 != null ? depth0.tls_version : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"tls_version","hash":{},"data":data}) : helper)))
    + " "
    + alias4(((helper = (helper = helpers.tls_cipher_suite || (depth0 != null ? depth0.tls_cipher_suite : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"tls_cipher_suite","hash":{},"data":data}) : helper)))
    + " "
    + alias4(((helper = (helper = helpers.tls_negotiated_protocol || (depth0 != null ? depth0.tls_negotiated_protocol : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"tls_negotiated_protocol","hash":{},"data":data}) : helper)))
    + " mutual:"
    + alias4(((helper = (helper = helpers.tls_negotiated_protocol_is_mutual || (depth0 != null ? depth0.tls_negotiated_protocol_is_mutual : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"tls_negotiated_protocol_is_mutual","hash":{},"data":data}) : helper)))
    + "\"";
},"34":function(container,depth0,helpers,partials,data) {
    return "                    <span class=\"label label-default\">Deflate</span>\n";
},"36":function(container,depth0,helpers,partials,data) {
    return "                    <span class=\"label label-primary\">Snappy</span>\n";
},"38":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                    <span class=\"label label-success\">\n                    "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.auth_identity_url : depth0),{"name":"if","hash":{},"fn":container.program(39, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                    <span class=\"glyphicon glyphicon-user white\" title=\"Authed"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.auth_identity : depth0),{"name":"if","hash":{},"fn":container.program(41, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\"></span>\n                    "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.auth_identity_url : depth0),{"name":"if","hash":{},"fn":container.program(43, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                    </span>\n";
},"39":function(container,depth0,helpers,partials,data) {
    var helper;

  return "<a href=\""
    + container.escapeExpression(((helper = (helper = helpers.auth_identity_url || (depth0 != null ? depth0.auth_identity_url : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"auth_identity_url","hash":{},"data":data}) : helper)))
    + "\">";
},"41":function(container,depth0,helpers,partials,data) {
    var helper;

  return " Identity:"
    + container.escapeExpression(((helper = (helper = helpers.auth_identity || (depth0 != null ? depth0.auth_identity : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"auth_identity","hash":{},"data":data}) : helper)));
},"43":function(container,depth0,helpers,partials,data) {
    return "</a>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return ((stack1 = container.invokePartial(partials.warning,depth0,{"name":"warning","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(partials.error,depth0,{"name":"error","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n<ol class=\"breadcrumb\">\n    <li><a class=\"link\" href=\""
    + alias3((helpers.basePath || (depth0 && depth0.basePath) || alias2).call(alias1,"/nodes",{"name":"basePath","hash":{},"data":data}))
    + "\">Nodes</a>\n    <li class=\"active\">"
    + alias3(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</li>\n</ol>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.graph_active : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n<div class=\"row\">\n    <div class=\"col-md-12\">\n"
    + ((stack1 = helpers.unless.call(alias1,((stack1 = (depth0 != null ? depth0.topics : depth0)) != null ? stack1.length : stack1),{"name":"unless","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.program(5, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true,"useDepths":true});
