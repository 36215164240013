// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "<div class=\"row\">\n    <div class=\"col-md-6\">\n        <div class=\"alert alert-warning\">\n            <h4>Notice</h4> No producers exist for this topic/channel.\n            <p>See <a class=\"link\" href=\""
    + container.escapeExpression((helpers.basePath || (depth0 && depth0.basePath) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"/lookup",{"name":"basePath","hash":{},"data":data}))
    + "\">Lookup</a> for more information.\n        </div>\n    </div>\n</div>\n";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isAdmin : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n<div class=\"row\">\n    <div class=\"col-md-12\">\n    <h4>Channel</h4>\n    <table class=\"table table-bordered table-condensed\">\n        <tr>\n            <th>&nbsp;</th>\n            <th colspan=\"4\" class=\"text-center\">Message Queues</th>\n            <th colspan=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.graph_active : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.program(11, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "\" class=\"text-center\">Statistics</th>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.e2e_processing_latency : depth0)) != null ? stack1.percentiles : stack1)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </tr>\n        <tr>\n            <th>NSQd Host</th>\n            <th>Depth</th>\n            <th>Memory + Disk</th>\n            <th>In-Flight</th>\n            <th>Deferred</th>\n            <th>Requeued</th>\n            <th>Timed Out</th>\n            <th>Messages</th>\n            "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.graph_active : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n            <th>Connections</th>\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0.e2e_processing_latency : depth0)) != null ? stack1.percentiles : stack1),{"name":"each","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </tr>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.nodes : depth0),{"name":"each","hash":{},"fn":container.program(19, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        <tr class=\"info\">\n            <td>Total:</td>\n            <td>"
    + alias3((helpers.commafy || (depth0 && depth0.commafy) || alias2).call(alias1,(depth0 != null ? depth0.depth : depth0),{"name":"commafy","hash":{},"data":data}))
    + "</td>\n            <td>"
    + alias3((helpers.commafy || (depth0 && depth0.commafy) || alias2).call(alias1,(depth0 != null ? depth0.memory_depth : depth0),{"name":"commafy","hash":{},"data":data}))
    + " + "
    + alias3((helpers.commafy || (depth0 && depth0.commafy) || alias2).call(alias1,(depth0 != null ? depth0.backend_depth : depth0),{"name":"commafy","hash":{},"data":data}))
    + "</td>\n            <td>"
    + alias3((helpers.commafy || (depth0 && depth0.commafy) || alias2).call(alias1,(depth0 != null ? depth0.in_flight_count : depth0),{"name":"commafy","hash":{},"data":data}))
    + "</td>\n            <td>"
    + alias3((helpers.commafy || (depth0 && depth0.commafy) || alias2).call(alias1,(depth0 != null ? depth0.deferred_count : depth0),{"name":"commafy","hash":{},"data":data}))
    + "</td>\n            <td>"
    + alias3((helpers.commafy || (depth0 && depth0.commafy) || alias2).call(alias1,(depth0 != null ? depth0.requeue_count : depth0),{"name":"commafy","hash":{},"data":data}))
    + "</td>\n            <td>"
    + alias3((helpers.commafy || (depth0 && depth0.commafy) || alias2).call(alias1,(depth0 != null ? depth0.timeout_count : depth0),{"name":"commafy","hash":{},"data":data}))
    + "</td>\n            <td>"
    + alias3((helpers.commafy || (depth0 && depth0.commafy) || alias2).call(alias1,(depth0 != null ? depth0.message_count : depth0),{"name":"commafy","hash":{},"data":data}))
    + "</td>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.graph_active : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            <td>"
    + alias3((helpers.commafy || (depth0 && depth0.commafy) || alias2).call(alias1,(depth0 != null ? depth0.client_count : depth0),{"name":"commafy","hash":{},"data":data}))
    + "</td>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.e2e_processing_latency : depth0)) != null ? stack1.percentiles : stack1)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(28, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </tr>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.graph_active : depth0),{"name":"if","hash":{},"fn":container.program(34, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </table>\n    </div>\n</div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"row channel-actions\">\n    <div class=\"col-md-2\">\n        <button class=\"btn btn-medium btn-warning\" data-action=\"empty\">Empty Queue</button>\n    </div>\n    <div class=\"col-md-2\">\n        <button class=\"btn btn-medium btn-danger\" data-action=\"delete\">Delete Channel</button>\n    </div>\n    <div class=\"col-md-2\">\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.paused : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "        <button class=\"btn btn-medium btn-success\" data-action=\"unpause\">UnPause Channel</button>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "        <button class=\"btn btn-medium btn-primary\" data-action=\"pause\">Pause Channel</button>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "5";
},"11":function(container,depth0,helpers,partials,data) {
    return "4";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <th colspan=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.e2e_processing_latency : depth0)) != null ? stack1.percentiles : stack1)) != null ? stack1.length : stack1), depth0))
    + "\">E2E Processing Latency</th>\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "<th>Rate</th>";
},"17":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "                <th>"
    + alias3((helpers.floatToPercent || (depth0 && depth0.floatToPercent) || alias2).call(alias1,(depth0 != null ? depth0.quantile : depth0),{"name":"floatToPercent","hash":{},"data":data}))
    + "<sup>"
    + alias3((helpers.percSuffix || (depth0 && depth0.percSuffix) || alias2).call(alias1,(depth0 != null ? depth0.quantile : depth0),{"name":"percSuffix","hash":{},"data":data}))
    + "</sup></th>\n";
},"19":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "        <tr>\n            <td>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.show_broadcast_address : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0, blockParams, depths),"inverse":container.program(22, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "                "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.paused : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n            </td>\n            <td>"
    + alias3((helpers.commafy || (depth0 && depth0.commafy) || alias2).call(alias1,(depth0 != null ? depth0.depth : depth0),{"name":"commafy","hash":{},"data":data}))
    + "</td>\n            <td>"
    + alias3((helpers.commafy || (depth0 && depth0.commafy) || alias2).call(alias1,(depth0 != null ? depth0.memory_depth : depth0),{"name":"commafy","hash":{},"data":data}))
    + " + "
    + alias3((helpers.commafy || (depth0 && depth0.commafy) || alias2).call(alias1,(depth0 != null ? depth0.backend_depth : depth0),{"name":"commafy","hash":{},"data":data}))
    + "</td>\n            <td>"
    + alias3((helpers.commafy || (depth0 && depth0.commafy) || alias2).call(alias1,(depth0 != null ? depth0.in_flight_count : depth0),{"name":"commafy","hash":{},"data":data}))
    + "</td>\n            <td>"
    + alias3((helpers.commafy || (depth0 && depth0.commafy) || alias2).call(alias1,(depth0 != null ? depth0.deferred_count : depth0),{"name":"commafy","hash":{},"data":data}))
    + "</td>\n            <td>"
    + alias3((helpers.commafy || (depth0 && depth0.commafy) || alias2).call(alias1,(depth0 != null ? depth0.requeue_count : depth0),{"name":"commafy","hash":{},"data":data}))
    + "</td>\n            <td>"
    + alias3((helpers.commafy || (depth0 && depth0.commafy) || alias2).call(alias1,(depth0 != null ? depth0.timeout_count : depth0),{"name":"commafy","hash":{},"data":data}))
    + "</td>\n            <td>"
    + alias3((helpers.commafy || (depth0 && depth0.commafy) || alias2).call(alias1,(depth0 != null ? depth0.message_count : depth0),{"name":"commafy","hash":{},"data":data}))
    + "</td>\n"
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].graph_active : depths[1]),{"name":"if","hash":{},"fn":container.program(26, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            <td>"
    + alias3((helpers.commafy || (depth0 && depth0.commafy) || alias2).call(alias1,(depth0 != null ? depth0.client_count : depth0),{"name":"commafy","hash":{},"data":data}))
    + "</td>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.e2e_processing_latency : depth0)) != null ? stack1.percentiles : stack1)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(28, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </tr>\n"
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].graph_active : depths[1]),{"name":"if","hash":{},"fn":container.program(31, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"20":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                "
    + alias4(((helper = (helper = helpers.hostname_port || (depth0 != null ? depth0.hostname_port : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"hostname_port","hash":{},"data":data}) : helper)))
    + " (<a class=\"link\" href=\""
    + alias4((helpers.basePath || (depth0 && depth0.basePath) || alias2).call(alias1,"/nodes",{"name":"basePath","hash":{},"data":data}))
    + "/"
    + alias4(((helper = (helper = helpers.node || (depth0 != null ? depth0.node : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"node","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.node || (depth0 != null ? depth0.node : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"node","hash":{},"data":data}) : helper)))
    + "</a>)\n";
},"22":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function";

  return "                <a class=\"link\" href=\""
    + alias3((helpers.basePath || (depth0 && depth0.basePath) || alias2).call(alias1,"/nodes",{"name":"basePath","hash":{},"data":data}))
    + "/"
    + alias3(((helper = (helper = helpers.node || (depth0 != null ? depth0.node : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"node","hash":{},"data":data}) : helper)))
    + "\">"
    + alias3(((helper = (helper = helpers.hostname_port || (depth0 != null ? depth0.hostname_port : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"hostname_port","hash":{},"data":data}) : helper)))
    + "</a>\n";
},"24":function(container,depth0,helpers,partials,data) {
    return " <span class=\"label label-primary\">paused</span>";
},"26":function(container,depth0,helpers,partials,data) {
    return "                <td class=\"bold rate\" target=\""
    + container.escapeExpression((helpers.rate || (depth0 && depth0.rate) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"topic",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.topic_name : depth0),"",{"name":"rate","hash":{},"data":data}))
    + "\"></td>\n";
},"28":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.e2e_processing_latency : depth0)) != null ? stack1.percentiles : stack1),{"name":"each","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"29":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "                <td>\n                    <span title=\""
    + alias3((helpers.floatToPercent || (depth0 && depth0.floatToPercent) || alias2).call(alias1,(depth0 != null ? depth0.quantile : depth0),{"name":"floatToPercent","hash":{},"data":data}))
    + ": min = "
    + alias3((helpers.nanotohuman || (depth0 && depth0.nanotohuman) || alias2).call(alias1,(depth0 != null ? depth0.min : depth0),{"name":"nanotohuman","hash":{},"data":data}))
    + ", max = "
    + alias3((helpers.nanotohuman || (depth0 && depth0.nanotohuman) || alias2).call(alias1,(depth0 != null ? depth0.max : depth0),{"name":"nanotohuman","hash":{},"data":data}))
    + "\">"
    + alias3((helpers.nanotohuman || (depth0 && depth0.nanotohuman) || alias2).call(alias1,(depth0 != null ? depth0.average : depth0),{"name":"nanotohuman","hash":{},"data":data}))
    + "</span>\n                </td>\n";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "        <tr class=\"graph-row\">\n            <td></td>\n            <td><a href=\""
    + alias3((helpers.large_graph || (depth0 && depth0.large_graph) || alias2).call(alias1,"channel",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.topic_name : depth0),(depth0 != null ? depth0.channel_name : depth0),"depth",{"name":"large_graph","hash":{},"data":data}))
    + "\"><img width=\"120\" height=\"20\"  src=\""
    + alias3((helpers.sparkline || (depth0 && depth0.sparkline) || alias2).call(alias1,"channel",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.topic_name : depth0),(depth0 != null ? depth0.channel_name : depth0),"depth",{"name":"sparkline","hash":{},"data":data}))
    + "\"></a></td>\n            <td></td>\n            <td><a href=\""
    + alias3((helpers.large_graph || (depth0 && depth0.large_graph) || alias2).call(alias1,"channel",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.topic_name : depth0),(depth0 != null ? depth0.channel_name : depth0),"in_flight_count",{"name":"large_graph","hash":{},"data":data}))
    + "\"><img width=\"120\" height=\"20\"  src=\""
    + alias3((helpers.sparkline || (depth0 && depth0.sparkline) || alias2).call(alias1,"channel",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.topic_name : depth0),(depth0 != null ? depth0.channel_name : depth0),"in_flight_count",{"name":"sparkline","hash":{},"data":data}))
    + "\"></a></td>\n            <td><a href=\""
    + alias3((helpers.large_graph || (depth0 && depth0.large_graph) || alias2).call(alias1,"channel",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.topic_name : depth0),(depth0 != null ? depth0.channel_name : depth0),"deferred_count",{"name":"large_graph","hash":{},"data":data}))
    + "\"><img width=\"120\" height=\"20\"  src=\""
    + alias3((helpers.sparkline || (depth0 && depth0.sparkline) || alias2).call(alias1,"channel",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.topic_name : depth0),(depth0 != null ? depth0.channel_name : depth0),"deferred_count",{"name":"sparkline","hash":{},"data":data}))
    + "\"></a></td>\n            <td><a href=\""
    + alias3((helpers.large_graph || (depth0 && depth0.large_graph) || alias2).call(alias1,"channel",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.topic_name : depth0),(depth0 != null ? depth0.channel_name : depth0),"requeue_count",{"name":"large_graph","hash":{},"data":data}))
    + "\"><img width=\"120\" height=\"20\"  src=\""
    + alias3((helpers.sparkline || (depth0 && depth0.sparkline) || alias2).call(alias1,"channel",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.topic_name : depth0),(depth0 != null ? depth0.channel_name : depth0),"requeue_count",{"name":"sparkline","hash":{},"data":data}))
    + "\"></a></td>\n            <td><a href=\""
    + alias3((helpers.large_graph || (depth0 && depth0.large_graph) || alias2).call(alias1,"channel",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.topic_name : depth0),(depth0 != null ? depth0.channel_name : depth0),"timeout_count",{"name":"large_graph","hash":{},"data":data}))
    + "\"><img width=\"120\" height=\"20\"  src=\""
    + alias3((helpers.sparkline || (depth0 && depth0.sparkline) || alias2).call(alias1,"channel",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.topic_name : depth0),(depth0 != null ? depth0.channel_name : depth0),"timeout_count",{"name":"sparkline","hash":{},"data":data}))
    + "\"></a></td>\n            <td><a href=\""
    + alias3((helpers.large_graph || (depth0 && depth0.large_graph) || alias2).call(alias1,"channel",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.topic_name : depth0),(depth0 != null ? depth0.channel_name : depth0),"message_count",{"name":"large_graph","hash":{},"data":data}))
    + "\"><img width=\"120\" height=\"20\"  src=\""
    + alias3((helpers.sparkline || (depth0 && depth0.sparkline) || alias2).call(alias1,"channel",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.topic_name : depth0),(depth0 != null ? depth0.channel_name : depth0),"message_count",{"name":"sparkline","hash":{},"data":data}))
    + "\"></a></td>\n            <td></td>\n            <td><a href=\""
    + alias3((helpers.large_graph || (depth0 && depth0.large_graph) || alias2).call(alias1,"channel",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.topic_name : depth0),(depth0 != null ? depth0.channel_name : depth0),"clients",{"name":"large_graph","hash":{},"data":data}))
    + "\"><img width=\"120\" height=\"20\"  src=\""
    + alias3((helpers.sparkline || (depth0 && depth0.sparkline) || alias2).call(alias1,"channel",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.topic_name : depth0),(depth0 != null ? depth0.channel_name : depth0),"clients",{"name":"sparkline","hash":{},"data":data}))
    + "\"></a></td>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.e2e_processing_latency : depth0)) != null ? stack1.percentiles : stack1)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </tr>\n";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=helpers.helperMissing;

  return "            <td colspan=\""
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.e2e_processing_latency : depth0)) != null ? stack1.percentiles : stack1)) != null ? stack1.length : stack1), depth0))
    + "\">\n                <a href=\""
    + alias1((helpers.large_graph || (depth0 && depth0.large_graph) || alias3).call(alias2,"e2e",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.e2e_processing_latency : depth0),"","e2e_processing_latency",{"name":"large_graph","hash":{},"data":data}))
    + "\"><img width=\"120\" height=\"20\" src=\""
    + alias1((helpers.sparkline || (depth0 && depth0.sparkline) || alias3).call(alias2,"e2e",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.e2e_processing_latency : depth0),"","e2e_processing_latency",{"name":"sparkline","hash":{},"data":data}))
    + "\"></a>\n            </td>\n";
},"34":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "        <tr class=\"graph-row\">\n            <td></td>\n            <td><a href=\""
    + alias3((helpers.large_graph || (depth0 && depth0.large_graph) || alias2).call(alias1,"channel",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.topic_name : depth0),(depth0 != null ? depth0.channel_name : depth0),"depth",{"name":"large_graph","hash":{},"data":data}))
    + "\"><img width=\"120\" height=\"20\"  src=\""
    + alias3((helpers.sparkline || (depth0 && depth0.sparkline) || alias2).call(alias1,"channel",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.topic_name : depth0),(depth0 != null ? depth0.channel_name : depth0),"depth",{"name":"sparkline","hash":{},"data":data}))
    + "\"></a></td>\n            <td></td>\n            <td><a href=\""
    + alias3((helpers.large_graph || (depth0 && depth0.large_graph) || alias2).call(alias1,"channel",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.topic_name : depth0),(depth0 != null ? depth0.channel_name : depth0),"in_flight_count",{"name":"large_graph","hash":{},"data":data}))
    + "\"><img width=\"120\" height=\"20\"  src=\""
    + alias3((helpers.sparkline || (depth0 && depth0.sparkline) || alias2).call(alias1,"channel",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.topic_name : depth0),(depth0 != null ? depth0.channel_name : depth0),"in_flight_count",{"name":"sparkline","hash":{},"data":data}))
    + "\"></a></td>\n            <td><a href=\""
    + alias3((helpers.large_graph || (depth0 && depth0.large_graph) || alias2).call(alias1,"channel",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.topic_name : depth0),(depth0 != null ? depth0.channel_name : depth0),"deferred_count",{"name":"large_graph","hash":{},"data":data}))
    + "\"><img width=\"120\" height=\"20\"  src=\""
    + alias3((helpers.sparkline || (depth0 && depth0.sparkline) || alias2).call(alias1,"channel",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.topic_name : depth0),(depth0 != null ? depth0.channel_name : depth0),"deferred_count",{"name":"sparkline","hash":{},"data":data}))
    + "\"></a></td>\n            <td><a href=\""
    + alias3((helpers.large_graph || (depth0 && depth0.large_graph) || alias2).call(alias1,"channel",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.topic_name : depth0),(depth0 != null ? depth0.channel_name : depth0),"requeue_count",{"name":"large_graph","hash":{},"data":data}))
    + "\"><img width=\"120\" height=\"20\"  src=\""
    + alias3((helpers.sparkline || (depth0 && depth0.sparkline) || alias2).call(alias1,"channel",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.topic_name : depth0),(depth0 != null ? depth0.channel_name : depth0),"requeue_count",{"name":"sparkline","hash":{},"data":data}))
    + "\"></a></td>\n            <td><a href=\""
    + alias3((helpers.large_graph || (depth0 && depth0.large_graph) || alias2).call(alias1,"channel",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.topic_name : depth0),(depth0 != null ? depth0.channel_name : depth0),"timeout_count",{"name":"large_graph","hash":{},"data":data}))
    + "\"><img width=\"120\" height=\"20\"  src=\""
    + alias3((helpers.sparkline || (depth0 && depth0.sparkline) || alias2).call(alias1,"channel",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.topic_name : depth0),(depth0 != null ? depth0.channel_name : depth0),"timeout_count",{"name":"sparkline","hash":{},"data":data}))
    + "\"></a></td>\n            <td><a href=\""
    + alias3((helpers.large_graph || (depth0 && depth0.large_graph) || alias2).call(alias1,"channel",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.topic_name : depth0),(depth0 != null ? depth0.channel_name : depth0),"message_count",{"name":"large_graph","hash":{},"data":data}))
    + "\"><img width=\"120\" height=\"20\"  src=\""
    + alias3((helpers.sparkline || (depth0 && depth0.sparkline) || alias2).call(alias1,"channel",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.topic_name : depth0),(depth0 != null ? depth0.channel_name : depth0),"message_count",{"name":"sparkline","hash":{},"data":data}))
    + "\"></a></td>\n            <td></td>\n            <td><a href=\""
    + alias3((helpers.large_graph || (depth0 && depth0.large_graph) || alias2).call(alias1,"channel",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.topic_name : depth0),(depth0 != null ? depth0.channel_name : depth0),"clients",{"name":"large_graph","hash":{},"data":data}))
    + "\"><img width=\"120\" height=\"20\"  src=\""
    + alias3((helpers.sparkline || (depth0 && depth0.sparkline) || alias2).call(alias1,"channel",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.topic_name : depth0),(depth0 != null ? depth0.channel_name : depth0),"clients",{"name":"sparkline","hash":{},"data":data}))
    + "\"></a></td>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.e2e_processing_latency : depth0)) != null ? stack1.percentiles : stack1)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </tr>\n";
},"35":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=helpers.helperMissing;

  return "            <td colspan=\""
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.e2e_processing_latency : depth0)) != null ? stack1.percentiles : stack1)) != null ? stack1.length : stack1), depth0))
    + "\">\n                <a href=\""
    + alias1((helpers.large_graph || (depth0 && depth0.large_graph) || alias3).call(alias2,"e2e",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.e2e_processing_latency : depth0),"","e2e_processing_latency",{"name":"large_graph","hash":{},"data":data}))
    + "\"><img width=\"120\" height=\"20\"  src=\""
    + alias1((helpers.sparkline || (depth0 && depth0.sparkline) || alias3).call(alias2,"e2e",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.e2e_processing_latency : depth0),"","e2e_processing_latency",{"name":"sparkline","hash":{},"data":data}))
    + "\"></a>\n            </td>\n";
},"37":function(container,depth0,helpers,partials,data) {
    return "            <div class=\"alert alert-warning\"><h4>Notice</h4>No clients connected to this channel</div>\n";
},"39":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <table class=\"table table-bordered table-condensed\">\n            <tr>\n                <th>Client Host</th>\n                <th>User-Agent</th>\n                <th>Attributes</th>\n                <th>NSQd Host</th>\n                <th>In-Flight</th>\n                <th>Ready Count</th>\n                <th>Finished</th>\n                <th>Requeued</th>\n                <th>Messages</th>\n                <th>Connected</th>\n            </tr>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.clients : depth0),{"name":"each","hash":{},"fn":container.program(40, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </table>\n";
},"40":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <tr>\n                <td title=\""
    + alias4(((helper = (helper = helpers.remote_address || (depth0 != null ? depth0.remote_address : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"remote_address","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.hostname_port || (depth0 != null ? depth0.hostname_port : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"hostname_port","hash":{},"data":data}) : helper)))
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.show_client_id : depth0),{"name":"if","hash":{},"fn":container.program(41, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</td>\n                <td>"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.user_agent : depth0)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(43, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</td>\n                <td>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.sample_rate : depth0),{"name":"if","hash":{},"fn":container.program(45, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.tls : depth0),{"name":"if","hash":{},"fn":container.program(47, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.deflate : depth0),{"name":"if","hash":{},"fn":container.program(50, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.snappy : depth0),{"name":"if","hash":{},"fn":container.program(52, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.authed : depth0),{"name":"if","hash":{},"fn":container.program(54, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </td>\n                <td><a class=\"link\" href=\""
    + alias4((helpers.basePath || (depth0 && depth0.basePath) || alias2).call(alias1,"/nodes",{"name":"basePath","hash":{},"data":data}))
    + "/"
    + alias4(((helper = (helper = helpers.node || (depth0 != null ? depth0.node : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"node","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.node || (depth0 != null ? depth0.node : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"node","hash":{},"data":data}) : helper)))
    + "</a></td>\n                <td>"
    + alias4((helpers.commafy || (depth0 && depth0.commafy) || alias2).call(alias1,(depth0 != null ? depth0.in_flight_count : depth0),{"name":"commafy","hash":{},"data":data}))
    + "</td>\n                <td>"
    + alias4((helpers.commafy || (depth0 && depth0.commafy) || alias2).call(alias1,(depth0 != null ? depth0.ready_count : depth0),{"name":"commafy","hash":{},"data":data}))
    + "</td>\n                <td>"
    + alias4((helpers.commafy || (depth0 && depth0.commafy) || alias2).call(alias1,(depth0 != null ? depth0.finish_count : depth0),{"name":"commafy","hash":{},"data":data}))
    + "</td>\n                <td>"
    + alias4((helpers.commafy || (depth0 && depth0.commafy) || alias2).call(alias1,(depth0 != null ? depth0.requeue_count : depth0),{"name":"commafy","hash":{},"data":data}))
    + "</td>\n                <td>"
    + alias4((helpers.commafy || (depth0 && depth0.commafy) || alias2).call(alias1,(depth0 != null ? depth0.message_count : depth0),{"name":"commafy","hash":{},"data":data}))
    + "</td>\n                <td>"
    + alias4((helpers.nanotohuman || (depth0 && depth0.nanotohuman) || alias2).call(alias1,(depth0 != null ? depth0.connected : depth0),{"name":"nanotohuman","hash":{},"data":data}))
    + "</td>\n            </tr>\n";
},"41":function(container,depth0,helpers,partials,data) {
    var helper;

  return " ("
    + container.escapeExpression(((helper = (helper = helpers.client_id || (depth0 != null ? depth0.client_id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"client_id","hash":{},"data":data}) : helper)))
    + ")";
},"43":function(container,depth0,helpers,partials,data) {
    var helper;

  return "<small>"
    + container.escapeExpression(((helper = (helper = helpers.user_agent || (depth0 != null ? depth0.user_agent : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"user_agent","hash":{},"data":data}) : helper)))
    + "</small>";
},"45":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                        <span class=\"label label-info\">Sampled "
    + container.escapeExpression(((helper = (helper = helpers.sample_rate || (depth0 != null ? depth0.sample_rate : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"sample_rate","hash":{},"data":data}) : helper)))
    + "%</span>\n";
},"47":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        <span class=\"label label-warning\" "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.tls_version : depth0),{"name":"if","hash":{},"fn":container.program(48, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">TLS</span>\n";
},"48":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "title=\""
    + alias4(((helper = (helper = helpers.tls_version || (depth0 != null ? depth0.tls_version : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"tls_version","hash":{},"data":data}) : helper)))
    + " "
    + alias4(((helper = (helper = helpers.tls_cipher_suite || (depth0 != null ? depth0.tls_cipher_suite : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"tls_cipher_suite","hash":{},"data":data}) : helper)))
    + " "
    + alias4(((helper = (helper = helpers.tls_negotiated_protocol || (depth0 != null ? depth0.tls_negotiated_protocol : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"tls_negotiated_protocol","hash":{},"data":data}) : helper)))
    + " mutual:"
    + alias4(((helper = (helper = helpers.tls_negotiated_protocol_is_mutual || (depth0 != null ? depth0.tls_negotiated_protocol_is_mutual : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"tls_negotiated_protocol_is_mutual","hash":{},"data":data}) : helper)))
    + "\"";
},"50":function(container,depth0,helpers,partials,data) {
    return "                        <span class=\"label label-default\">Deflate</span>\n";
},"52":function(container,depth0,helpers,partials,data) {
    return "                        <span class=\"label label-primary\">Snappy</span>\n";
},"54":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                        <span class=\"label label-success\">\n                        "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.auth_identity_url : depth0),{"name":"if","hash":{},"fn":container.program(55, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                        <span class=\"glyphicon glyphicon-user white\" title=\"Authed"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.auth_identity : depth0),{"name":"if","hash":{},"fn":container.program(57, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\"></span>\n                        "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.auth_identity_url : depth0),{"name":"if","hash":{},"fn":container.program(59, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                        </span>\n";
},"55":function(container,depth0,helpers,partials,data) {
    var helper;

  return "<a href=\""
    + container.escapeExpression(((helper = (helper = helpers.auth_identity_url || (depth0 != null ? depth0.auth_identity_url : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"auth_identity_url","hash":{},"data":data}) : helper)))
    + "\">";
},"57":function(container,depth0,helpers,partials,data) {
    var helper;

  return " Identity:"
    + container.escapeExpression(((helper = (helper = helpers.auth_identity || (depth0 != null ? depth0.auth_identity : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"auth_identity","hash":{},"data":data}) : helper)));
},"59":function(container,depth0,helpers,partials,data) {
    return "</a>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function";

  return ((stack1 = container.invokePartial(partials.warning,depth0,{"name":"warning","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(partials.error,depth0,{"name":"error","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n<ol class=\"breadcrumb\">\n  <li><a class=\"link\" href=\""
    + alias3((helpers.basePath || (depth0 && depth0.basePath) || alias2).call(alias1,"/",{"name":"basePath","hash":{},"data":data}))
    + "\">Streams</a>\n  <li><a class=\"link\" href=\""
    + alias3((helpers.basePath || (depth0 && depth0.basePath) || alias2).call(alias1,"/topics",{"name":"basePath","hash":{},"data":data}))
    + "/"
    + alias3((helpers.urlencode || (depth0 && depth0.urlencode) || alias2).call(alias1,(depth0 != null ? depth0.topic : depth0),{"name":"urlencode","hash":{},"data":data}))
    + "\">"
    + alias3(((helper = (helper = helpers.topic || (depth0 != null ? depth0.topic : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"topic","hash":{},"data":data}) : helper)))
    + "</a>\n  <li class=\"active\">"
    + alias3(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</li>\n</ol>\n\n<div class=\"row\">\n    <div class=\"col-md-6\">\n        <blockquote>\n            <p>Topic: <strong>"
    + alias3(((helper = (helper = helpers.topic || (depth0 != null ? depth0.topic : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"topic","hash":{},"data":data}) : helper)))
    + "</strong>\n            <p>Channel: <strong>"
    + alias3(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</strong>\n        </blockquote>\n    </div>\n</div>\n\n"
    + ((stack1 = helpers.unless.call(alias1,((stack1 = (depth0 != null ? depth0.nodes : depth0)) != null ? stack1.length : stack1),{"name":"unless","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(3, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "\n<h4>Client Connections</h4>\n\n<div class=\"row\">\n    <div class=\"col-md-12\">\n"
    + ((stack1 = helpers.unless.call(alias1,((stack1 = (depth0 != null ? depth0.clients : depth0)) != null ? stack1.length : stack1),{"name":"unless","hash":{},"fn":container.program(37, data, 0, blockParams, depths),"inverse":container.program(39, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"usePartial":true,"useData":true,"useDepths":true});
