// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "<div class=\"alert alert-warning\">\n    <h4>Notice</h4> nsqadmin is not configured with nsqlookupd hosts\n</div>\n";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"row\">\n    <div class=\"col-md-4\">\n        <table class=\"table table-bordered table-condensed\">\n            <tr>\n                <th>nsqlookupd Host</th>\n            </tr>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.nsqlookupd : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </table>\n    </div>\n</div>\n\n<div class=\"row\">\n    <div class=\"col-md-4\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.topics : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.program(10, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "    </div>\n</div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isAdmin : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    return "            <tr><td>"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</td></tr>\n";
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "        <div class=\"alert alert-info\">\n            Below is a tree of Topics/Channels that are currently inactive (i.e. not produced on any nsqd in the cluster but are present in the lookup data)\n        </div>\n        <ul>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.topics : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </ul>\n";
},"7":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <li>\n                <button class=\"btn-link red delete-topic-link\" data-topic=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\" style=\"padding: 0 6px; border: 0;\">✘</button> <a class=\"link\" href=\""
    + alias4((helpers.basePath || (depth0 && depth0.basePath) || alias2).call(alias1,"/topics",{"name":"basePath","hash":{},"data":data}))
    + "/"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</a>\n                <ul>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.channels : depth0),{"name":"each","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </ul>\n            </li>\n";
},"8":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "                    <li>\n                        <button class=\"btn-link red delete-channel-link\" data-topic=\""
    + alias2(alias1((depths[1] != null ? depths[1].name : depths[1]), depth0))
    + "\" data-channel=\""
    + alias2(alias1(depth0, depth0))
    + "\" style=\"padding: 0 6px; border: 0;\">✘</button> <a class=\"link\" href=\""
    + alias2((helpers.basePath || (depth0 && depth0.basePath) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"/topics",{"name":"basePath","hash":{},"data":data}))
    + "/"
    + alias2(alias1((depths[1] != null ? depths[1].name : depths[1]), depth0))
    + "/"
    + alias2(alias1(depth0, depth0))
    + "\">"
    + alias2(alias1(depth0, depth0))
    + "</a>\n                    </li>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"alert alert-warning\"><h4>Notice</h4>No inactive Topics</div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "<div class=\"row\">\n    <div class=\"col-md-4\">\n        <form class=\"hierarchy\">\n            <legend>Create Topic/Channel</legend>\n            <div class=\"alert alert-info\">\n                <p>This provides a way to setup a stream hierarchy\n                before services are deployed to production.\n                <p>If <em>Channel Name</em> is empty, just the topic is created.\n            </div>\n            <div class=\"form-group\">\n                <input type=\"text\" name=\"topic\" placeholder=\"Topic Name\">\n                <input type=\"text\" name=\"channel\" placeholder=\"Channel Name\">\n            </div>\n            <button class=\"btn btn-default\" type=\"submit\">Create</button>\n        </form>\n    </div>\n</div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.warning,depth0,{"name":"warning","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(partials.error,depth0,{"name":"error","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n<div class=\"row\">\n    <div class=\"col-md-12\">\n        <h2>Lookup</h2>\n    </div>\n</div>\n\n"
    + ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.nsqlookupd : depth0)) != null ? stack1.length : stack1),{"name":"unless","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(3, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "");
},"usePartial":true,"useData":true,"useDepths":true});
