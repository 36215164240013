// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "        <table class=\"table table-condensed table-bordered\">\n            <tr>\n                <th>Topic</th>\n                "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.graph_active : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.graph_active : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.graph_active : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n            </tr>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.collection : depth0),{"name":"each","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </table>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "<th width=\"120\">Depth</th>";
},"4":function(container,depth0,helpers,partials,data) {
    return "<th width=\"120\">Messages</th>";
},"6":function(container,depth0,helpers,partials,data) {
    return "<th width=\"120\">Rate</th>";
},"8":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "            <tr>\n                <td><a class=\"link\" href=\""
    + alias3((helpers.basePath || (depth0 && depth0.basePath) || alias2).call(alias1,"/topics",{"name":"basePath","hash":{},"data":data}))
    + "/"
    + alias3((helpers.urlencode || (depth0 && depth0.urlencode) || alias2).call(alias1,(depth0 != null ? depth0.name : depth0),{"name":"urlencode","hash":{},"data":data}))
    + "\">"
    + alias3(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</a></td>\n                "
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].graph_active : depths[1]),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].graph_active : depths[1]),{"name":"if","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].graph_active : depths[1]),{"name":"if","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n            </tr>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<td><a class=\"link\" href=\""
    + alias3((helpers.basePath || (depth0 && depth0.basePath) || alias2).call(alias1,"/topics",{"name":"basePath","hash":{},"data":data}))
    + "/"
    + alias3((helpers.urlencode || (depth0 && depth0.urlencode) || alias2).call(alias1,(depth0 != null ? depth0.name : depth0),{"name":"urlencode","hash":{},"data":data}))
    + "\"><img width=\"120\" height=\"20\" src=\""
    + alias3((helpers.sparkline || (depth0 && depth0.sparkline) || alias2).call(alias1,"topic","",(depth0 != null ? depth0.name : depth0),"","depth",{"name":"sparkline","hash":{},"data":data}))
    + "\"></a></td>";
},"11":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<td><a class=\"link\" href=\""
    + alias3((helpers.basePath || (depth0 && depth0.basePath) || alias2).call(alias1,"/topics",{"name":"basePath","hash":{},"data":data}))
    + "/"
    + alias3((helpers.urlencode || (depth0 && depth0.urlencode) || alias2).call(alias1,(depth0 != null ? depth0.name : depth0),{"name":"urlencode","hash":{},"data":data}))
    + "\"><img width=\"120\" height=\"20\" src=\""
    + alias3((helpers.sparkline || (depth0 && depth0.sparkline) || alias2).call(alias1,"topic","",(depth0 != null ? depth0.name : depth0),"","message_count",{"name":"sparkline","hash":{},"data":data}))
    + "\"></a></td>";
},"13":function(container,depth0,helpers,partials,data) {
    return "<td class=\"bold rate\" target=\""
    + container.escapeExpression((helpers.rate || (depth0 && depth0.rate) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"topic","*",(depth0 != null ? depth0.name : depth0),"",{"name":"rate","hash":{},"data":data}))
    + "\"></td>";
},"15":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"alert alert-warning\"><h4>Notice</h4>No Topics Found</div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.warning,depth0,{"name":"warning","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(partials.error,depth0,{"name":"error","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n<div class=\"row\">\n    <div class=\"col-md-12\">\n        <h2>Topics</h2>\n    </div>\n</div>\n\n<div class=\"row\">\n    <div class=\"col-md-6\">\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.collection : depth0)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(15, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"usePartial":true,"useData":true,"useDepths":true});
