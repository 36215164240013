// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                <li class=\"dropdown\">\n                    <a href=\"#\" class=\"dropdown-toggle\" data-toggle=\"dropdown\" role=\"button\" aria-expanded=\"false\"><span class=\"glyphicon glyphicon-picture white\"></span> "
    + container.escapeExpression(((helper = (helper = helpers.graph_interval || (depth0 != null ? depth0.graph_interval : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"graph_interval","hash":{},"data":data}) : helper)))
    + " <span class=\"caret\"></span></a>\n                    <ul class=\"dropdown-menu\">\n                      <li class=\"dropdown-header\">Graph Timeframe</li>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.graph_intervals : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                    </ul>\n                </li>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "                        <li><a href=\"javascript:;\">"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</a></li>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<nav class=\"navbar navbar-inverse navbar-static-top\">\n    <div class=\"container-fluid\">\n        <div class=\"navbar-header\">\n            <button type=\"button\" class=\"navbar-toggle collapsed\" data-toggle=\"collapse\" data-target=\"#navbar\">\n                <span class=\"sr-only\">Toggle navigation</span>\n                <span class=\"icon-bar\"></span>\n                <span class=\"icon-bar\"></span>\n                <span class=\"icon-bar\"></span>\n            </button>\n            <a class=\"navbar-brand\" href=\""
    + alias3((helpers.basePath || (depth0 && depth0.basePath) || alias2).call(alias1,"/",{"name":"basePath","hash":{},"data":data}))
    + "\"><img src=\""
    + alias3((helpers.basePath || (depth0 && depth0.basePath) || alias2).call(alias1,"/static/nsq_blue.png",{"name":"basePath","hash":{},"data":data}))
    + "\" width=\"30\" height=\"30\">NSQ</a>\n        </div>\n        <div class=\"collapse navbar-collapse\" id=\"navbar\">\n            <ul class=\"nav navbar-nav\">\n                <li><a class=\"link\" href=\""
    + alias3((helpers.basePath || (depth0 && depth0.basePath) || alias2).call(alias1,"/",{"name":"basePath","hash":{},"data":data}))
    + "\">Streams</a></li>\n                <li><a class=\"link\" href=\""
    + alias3((helpers.basePath || (depth0 && depth0.basePath) || alias2).call(alias1,"/nodes",{"name":"basePath","hash":{},"data":data}))
    + "\">Nodes</a></li>\n                <li><a class=\"link\" href=\""
    + alias3((helpers.basePath || (depth0 && depth0.basePath) || alias2).call(alias1,"/counter",{"name":"basePath","hash":{},"data":data}))
    + "\">Counter</a></li>\n                <li><a class=\"link\" href=\""
    + alias3((helpers.basePath || (depth0 && depth0.basePath) || alias2).call(alias1,"/lookup",{"name":"basePath","hash":{},"data":data}))
    + "\">Lookup</a></li>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.graph_enabled : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </ul>\n            <ul class=\"nav navbar-nav navbar-right\">\n                <li><a href=\"http://nsq.io/\">Documentation</a></li>\n                <li><a href=\"https://github.com/nsqio/nsq\">GitHub</a></li>\n                <li class=\"hidden-xs\"><p class=\"navbar-text\"><span class=\"label label-success\">v"
    + alias3(((helper = (helper = helpers.version || (depth0 != null ? depth0.version : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"version","hash":{},"data":data}) : helper)))
    + "</span></p></li>\n                </ul>\n            </ul>\n        </div>\n    </div>\n</nav>\n";
},"useData":true});
